import React from 'react';
import {Helmet} from 'react-helmet';
import Layout from '../components/layout';
import {useAnnouncementPage} from '../hooks/queries';
import {displayRichText} from '../utils';
import '../styles/pages/announcement.scss';

const Announcement = () => {
  const {title, announcementTitle, announcementText, aboutElse, mediaContact} =
    useAnnouncementPage();
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
      </Helmet>

      <div className="announcement tablet:py-20 py-14 w-full m-auto px-4">
        <span className="desktop:text-4xl text-3xl font-bold text-center">
          {displayRichText(announcementTitle.raw)}
        </span>
        <div className="desktop:text-xl text-lg">
          {displayRichText(announcementText.raw)}
        </div>
        <div className="desktop:text-xl text-lg pt-8">
          {displayRichText(aboutElse.raw)}
        </div>
        <div className="desktop:text-xl text-lg pt-8">
          {displayRichText(mediaContact.raw)}
        </div>
      </div>
    </Layout>
  );
};

export default Announcement;
